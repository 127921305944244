<template>
  <v-menu
    v-model="menu2"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        outlined
        :label="dateLabel"
        class="datepicker-input"
        :class="classes"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="menu2 = false"
      @change="dateChange"
      :value="initialValue"
      :min="lowestDate"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    namespace: {
      type: String,
      required: true,
    },
    lowestDate: {
      type: String,
      default: function() {
        return new Date(
          new Date().setFullYear(new Date().getFullYear() - 1)
        ).toISOString();
      },
      required: false,
    },
    fromDate: {
      type: Boolean,
      required: false,
    },
    toDate: {
      type: Boolean,
      required: false,
    },
    initialValue: {
      type: String,
    },
    defaultDateLabel: {
      type: String,
      default: "Pick a date",
      required: false,
    },
    classes: {
      type: String,
      default: " mt-4 mr-md-5",
      required: false,
    },
  },
  data() {
    return {
      menu2: false,
      date: "",
    };
  },
  mounted() {
    if (this.fromDate) {
      this.$store.commit(`${this.namespace}/setFromDate`, this.initialValue);
    } else if (this.toDate) {
      this.updateModifiedToDate(this.initialValue);
    }

    this.date = this.initialValue;
  },
  computed: {
    dateLabel() {
      if (this.fromDate) {
        return "Date from";
      } else if (this.toDate) {
        return "Date to";
      } else {
        return this.defaultDateLabel;
      }
    },
  },
  methods: {
    dateChange(date) {
      if (this.fromDate) {
        this.$store.commit(`${this.namespace}/setFromDate`, date);
      } else if (this.toDate) {
        this.updateModifiedToDate(date);
      }
      this.$emit("dateChanged", date);
    },
    setDate(date) {
      this.date = date;
    },
    updateModifiedToDate(date) {
      const modifiedDate = new Date(
        new Date(date).getTime() + 24 * 60 * 60 * 1000
      ).toLocaleString("fr-CA", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      this.$store.commit(`${this.namespace}/setToDate`, modifiedDate);
    },
  },
};
</script>
<style lang="scss" scoped>
.datepicker-input {
  max-width: 300px;
  width: 300px;
  ::v-deep .v-text-field__details {
    display: none;
  }
}

.v-picker {
  width: 100%;
}

.v-menu__content {
  width: 300px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .datepicker-input {
    width: 100%;
    max-width: 100%;
  }

  .v-menu__content {
    width: 100%;
    width: calc(100% - 70px);
    max-width: 100%;
  }
}
</style>
